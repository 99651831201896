import Lines1 from './lines_1.svg';
import Lines2 from './lines_2.svg';
import Lines3 from './lines_3.svg';
import Lines4 from './lines_4.svg';
import Icon1 from './icon_1.svg';
import Icon2 from './icon_2.svg';
import Icon3 from './icon_3.svg';
import Icon4 from './icon_4.svg';

const mappingdata = [
  {
    id: '1',
    hexcolor: '#893952',
    color: 'rgb(137, 57, 82)',
    url: Lines1,
    icon: Icon1,
    title: {
      de: 'Fachliches Lernen im vorschulischen und schulischen Bereich',
      en: 'Domain-Specific Learning in Kindergarten and School',
    },
    target: {
      de: '/de/forschen/forschungslinien/forschungslinie-fachliches-lernen-im-vorschulischen-und-schulischen-bereich',
      en: '/en/research/research-lines/research-line-domain-specific-learning-in-kindergarten-and-school',
    },
  },
  {
    id: '2',
    hexcolor: '#D77824',
    color: 'rgb(215, 120, 36)',
    url: Lines2,
    icon: Icon2,
    title: {
      de: 'Professionelle Kompetenz von Lehrkräften und pädagogischem Personal',
      en: 'Professional Competencies of Preschool and School Teachers',
    },
    target: {
      de: '/de/forschen/forschungslinien/forschungslinie-professionelle-kompetenz-von-lehrkraften-und-padagogischem-personal',
      en: '/en/research/research-lines/research-line-professional-competencies-of-pre-school-and-school-teachers',
    },
  },
  {
    id: '3',
    hexcolor: '#3A7E72',
    color: 'rgb(58, 126, 114)',
    url: Lines3,
    icon: Icon3,
    title: {
      de: 'Wissenschaftskommunikation und extracurriculares Lernen',
      en: 'Science Communication and Extracurricular Learning',
    },
    target: {
      de: '/de/forschen/forschungslinien/forschungslinie-wissenschaftskommunikation-und-extracurriculares-lernen',
      en: '/en/research/research-lines/research-line-science-communication-and-extracurricular-learning',
    },
  },
  {
    id: '4',
    hexcolor: '#4E6286',
    color: 'rgb(78, 98, 134)',
    url: Lines4,
    icon: Icon4,
    title: {
      de: 'Methodenforschung und Maschinelles Lernen',
      en: 'Methodological Research and Machine Learning',
    },
    target: {
      de: '/de/forschen/forschungslinien/forschungslinie-methodenforschung-und-maschinelles-lernen',
      en: '/en/research/research-lines/research-line-methodological-research-and-machine-learning',
    },
  },
];

const researchlines_mapping = {
  'forschungslinie-fachliches-lernen-im-vorschulischen-und-schulischen-bereich':
    mappingdata[0],
  'research-line-domain-specific-learning-in-kindergarten-and-school':
    mappingdata[0],
  'forschungslinie-professionelle-kompetenz-von-lehrkraften-und-padagogischem-personal':
    mappingdata[1],
  'research-line-professional-competencies-of-pre-school-and-school-teachers':
    mappingdata[1],
  'forschungslinie-wissenschaftskommunikation-und-extracurriculares-lernen':
    mappingdata[2],
  'research-line-science-communication-and-extracurricular-learning':
    mappingdata[2],
  'forschungslinie-methodenforschung-und-maschinelles-lernen': mappingdata[3],
  'research-line-methodological-research-and-machine-learning': mappingdata[3],
};

export { researchlines_mapping, mappingdata };
