/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */
import {
  ResearchlinesView,
  ResearchlinesEdit,
  SubnavigationEdit,
  SubnavigationView,
  QuoteView,
  QuoteEdit,
  TeaserBlockProjectBody,
  TeaserBlockEventBody,
  TeaserBlockPersonBody,
  TeaserBlockFileBody,
  LargeHeroEdit,
  LargeHeroView,
  LargeHeroDefaultBody,
  LargeHeroBodyRight,
  LargeHeroBodyLeft,
  PersonView,
  PublicationView,
  ProjectView,
  IPNSliderBody,
} from 'volto-ipn/components';
import BlockSettingsSchema from '@plone/volto/components/manage/Blocks/Block/Schema';
import divideverticalSVG from '@plone/volto/icons/divide-vertical.svg';
import navSVG from '@plone/volto/icons/nav.svg';
import crownSVG from '@plone/volto/icons/crown.svg';
import quoteSVG from '@plone/volto/icons/quote.svg';
import IPNNewsListingBlockTemplate from 'volto-ipn/components/Blocks/Listing/NewsTemplate';
import ProjectListingBlockTemplate from 'volto-ipn/components/Blocks/Listing/Projects';
import PersonsListingBlockTemplate from 'volto-ipn/components/Blocks/Listing/Persons';
import PublicationListingBlockTemplate from 'volto-ipn/components/Blocks/Listing/Publications';
import SearchResultsListingBlockTemplate from 'volto-ipn/components/Blocks/Listing/SearchResults';
import { imageBlockSchemaEnhancer } from 'volto-ipn/components/Blocks/Image/schema';
import { ImageBlockDataAdapter } from 'volto-ipn/components/Blocks/Image/adapter';
import Edit from 'volto-ipn/components/Blocks/Image/Edit';
import ImageView from 'volto-ipn/components/Blocks/Image/View';
import DefaultSliderBody from '@kitconcept/volto-slider-block/components/DefaultBody';
import { defineMessages } from 'react-intl';
import { defaultStylingSchema } from 'volto-ipn/components/Blocks/schema';
import { composeSchema } from '@plone/volto/helpers';
import IPNTextBlockView from 'volto-ipn/components/Blocks/Slate/View';
import plusSVG from 'volto-ipn/icons/plus.svg';
import minusSVG from 'volto-ipn/icons/minus.svg';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

const messages = defineMessages({
  more: {
    id: 'Link more',
    defaultMessage: 'Link more',
  },
  LinkTitle: {
    id: 'Link title',
    defaultMessage: 'Link Title',
  },
  LinkTo: {
    id: 'Link to',
    defaultMessage: 'Link to',
  },
  headlineTag: {
    id: 'Headline level',
    defaultMessage: 'Headline level',
  },
  footer: {
    id: 'Footer',
    defaultMessage: 'Footer',
  },
  hideImage: {
    id: 'Hide image',
    defaultMessage: 'Hide image',
  },
  showPersonDetails: {
    id: 'Show contact info for persons',
    defaultMessage: 'Show contact info for persons',
  },
  showDate: {
    id: 'Show date',
    defaultMessage: 'Show date',
  },
  externalURL: {
    id: 'External URL',
    defaultMessage: 'External URL',
  },
  goBack: {
    id: 'Go back',
    defaultMessage: 'Go back',
  },
  tracking: {
    id: 'Tracking',
    defaultMessage: 'Tracking',
  },
  clearFilters: {
    id: 'Clear filters',
    defaultMessage: 'Clear filters',
  },
  // Translations added in overridden templates:
  notFoundTitle: {
    id: 'not_found_title',
    defaultMessage: 'not_found_title',
  },
  notFoundDescription: {
    id: 'not_found_description',
    defaultMessage: 'not_found_description',
  },
  notFoundFrontpage: {
    id: 'not_found_frontpage',
    defaultMessage: 'not_found_frontpage',
  },
  notFoundFrontpageText: {
    id: 'not_found_frontpage_text',
    defaultMessage: 'not_found_frontpage_text',
  },
  notFoundTheIPN: {
    id: 'not_found_theipn',
    defaultMessage: 'not_found_theipn',
  },
  notFoundTheIPNText: {
    id: 'not_found_theipn_text',
    defaultMessage: 'not_found_theipn_text',
  },
  notFoundResearch: {
    id: 'not_found_research',
    defaultMessage: 'not_found_research',
  },
  notFoundResearchText: {
    id: 'not_found_research_text',
    defaultMessage: 'not_found_research_text',
  },
  notFoundSociety: {
    id: 'not_found_society',
    defaultMessage: 'not_found_society',
  },
  notFoundSocietyText: {
    id: 'not_found_society_text',
    defaultMessage: 'not_found_society_text',
  },
  notFoundFooter: {
    id: 'not_found_footer',
    defaultMessage: 'not_found_footer',
  },
  notFoundSearch: {
    id: 'not_found_search',
    defaultMessage: 'not_found_search',
  },
  notFoundContact: {
    id: 'not_found_contact',
    defaultMessage: 'not_found_contact',
  },
  AltText: {
    id: 'Alt text',
    defaultMessage: 'Alt text',
  },
  AltTextHint: {
    id: 'Alt text hint',
    defaultMessage: 'Leave empty if the image is purely decorative.',
  },
  AltTextHintLinkText: {
    id: 'Alt text hint link text',
    defaultMessage: 'Describe the purpose of the image.',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
});

// Extends teaser block with field data from custom content-types.
const IPNTeaserSchemaEnhancer = ({ schema, formData, intl }) => {
  schema.fieldsets[0].fields.push('externalURL');
  schema.fieldsets[0].fields.push('headlineTag');
  schema.fieldsets[0].fields.push('rights');
  schema.fieldsets[0].fields.push('footer');
  if (formData?.href?.[0]?.['@type'] === 'person') {
    schema.fieldsets[0].fields.push('showPersonDetails');
  }
  schema.fieldsets[0].fields.push('hideImage');
  if (!formData?.hideImage) {
    schema.fieldsets[0].fields.push('alt');
  }
  schema.properties = {
    ...schema.properties,
    headlineTag: {
      title: intl.formatMessage(messages.headlineTag),
      choices: [
        ['h2', 'h2'],
        ['h3', 'h3'],
        ['h4', 'h4'],
        ['h5', 'h5'],
      ],
      default: 'h2',
      noValueOption: false,
    },
    hideImage: {
      title: intl.formatMessage(messages.hideImage),
      type: 'boolean',
    },
    alt: {
      title: intl.formatMessage(messages.AltText),
      description: (
        <>
          <a
            href="https://www.w3.org/WAI/tutorials/images/decision-tree/"
            title={intl.formatMessage(messages.openLinkInNewTab)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {intl.formatMessage(messages.AltTextHintLinkText)}
          </a>{' '}
          {intl.formatMessage(messages.AltTextHint)}
        </>
      ),
    },
    showPersonDetails: {
      title: intl.formatMessage(messages.showPersonDetails),
      type: 'boolean',
    },
    footer: {
      title: intl.formatMessage(messages.footer),
    },
    externalURL: {
      title: intl.formatMessage(messages.externalURL),
      widget: 'url',
    },
  };
  schema.properties.href.selectedItemAttrs = [
    ...schema.properties.href.selectedItemAttrs,
    // Project data
    'acronym',
    'project_title',
    'researchlines',
    // Event data
    'start',
    'end',
    'whole_day',
    'open_end',
    // person data
    'org_units',
    'academic_degree',
    'phone_numbers',
    'has_email_address',
    // File date
    'getObjSize',
    'mime_type',
    // News Item (IAltText Behavior)
    'alttext',
    // Image (copyright & credits)
    'rights',
  ];
  schema.properties.preview_image.selectedItemAttrs = [
    ...schema.properties.preview_image.selectedItemAttrs,
    'rights',
  ];

  return schema;
};

const IPNListingSchemaEnhancer = ({ schema, formData, intl }) => {
  schema.properties = {
    ...schema.properties,
    showDate: {
      title: intl.formatMessage(messages.showDate),
      type: 'boolean',
    },
  };
  if (
    ['default', 'ipnnews'].includes(formData?.variation) &&
    formData?.querystring?.query.length
  ) {
    schema.fieldsets[0].fields.push('showDate');
  }
  return schema;
};

const IPNGridSchemaEnhancer = ({ schema, formData, intl }) => {
  schema.fieldsets = [
    ...schema.fieldsets,
    {
      id: 'linkmore',
      title: intl.formatMessage(messages.more),
      fields: ['linkTitle', 'linkHref'],
    },
  ];
  schema.properties = {
    ...schema.properties,
    linkTitle: {
      title: intl.formatMessage(messages.LinkTitle),
    },
    linkHref: {
      title: intl.formatMessage(messages.LinkTo),
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['Title', 'Description'],
      allowExternals: true,
    },
  };
  return schema;
};

const listingVariations = [
  {
    id: 'ipnnews',
    title: 'IPN News',
    template: IPNNewsListingBlockTemplate,
  },
  {
    id: 'projects',
    title: 'Projects',
    template: ProjectListingBlockTemplate,
  },
  {
    id: 'persons',
    title: 'Persons',
    template: PersonsListingBlockTemplate,
  },
  {
    id: 'publications',
    title: 'Publications',
    template: PublicationListingBlockTemplate,
  },
  {
    id: 'searchresults',
    title: 'Search Results',
    template: SearchResultsListingBlockTemplate,
  },
];

const applyConfig = (config) => {
  // Add here your project's configuration here by modifying `config` accordingly
  config.settings = {
    ...config.settings,
    defaultLanguage: 'de',
    isMultilingual: true,
    navDepth: 7,
    openExternalLinkInNewTab: true,
    supportedLanguages: ['en', 'de'],
  };
  config.settings.DSGVOBanner = {
    ...(config.settings.DSGVOBanner || {}),
    tracker: {
      type: 'matomo',
      id: 1,
      urlBase: 'https://tr.leibniz-ipn.de/',
    },
    modules: ['tracking', 'youtube'],
    privacy_url: {
      de: '/de/das-ipn/datenschutzerklaerung',
      en: '/en/the-ipn/datenschutzerklarung',
    },
  };
  config.blocks.blocksConfig.researchlines = {
    id: 'researchlines',
    title: 'Researchlines',
    icon: divideverticalSVG,
    group: 'common',
    view: ResearchlinesView,
    edit: ResearchlinesEdit,
    schema: BlockSettingsSchema,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.quote = {
    id: 'quote',
    title: 'Quote',
    icon: quoteSVG,
    group: 'common',
    view: QuoteView,
    edit: QuoteEdit,
    schema: BlockSettingsSchema,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.LargeHero = {
    id: 'LargeHero',
    title: 'Large Hero',
    icon: crownSVG,
    group: 'common',
    view: LargeHeroView,
    edit: LargeHeroEdit,
    schema: BlockSettingsSchema,
    schemaEnhancer: defaultStylingSchema,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    variations: [
      {
        id: 'default',
        title: 'Default',
        isDefault: true,
        template: LargeHeroDefaultBody,
      },
      {
        id: 'right',
        title: 'Image Right',
        isDefault: false,
        template: LargeHeroBodyRight,
      },
      {
        id: 'left',
        title: 'Image Left',
        isDefault: false,
        template: LargeHeroBodyLeft,
      },
    ],
  };
  config.blocks.blocksConfig.subnavigation = {
    id: 'subnavigation',
    title: 'Subnavigation',
    icon: navSVG,
    group: 'common',
    view: SubnavigationView,
    edit: SubnavigationEdit,
    schema: BlockSettingsSchema,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
  };

  // Image
  config.blocks.blocksConfig.image = {
    ...config.blocks.blocksConfig.image,
    view: ImageView,
    edit: Edit,
    schemaEnhancer: imageBlockSchemaEnhancer,
    dataAdapter: ImageBlockDataAdapter,
  };

  // Listing
  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    showLinkMore: true,
    allowed_headline_tags: [
      ['h2', 'h2'],
      ['h3', 'h3'],
      ['h4', 'h4'],
    ],
    variations: [
      ...config.blocks.blocksConfig.listing.variations,
      ...listingVariations,
    ],
    schemaEnhancer: composeSchema(
      defaultStylingSchema,
      IPNListingSchemaEnhancer,
    ),
  };

  // Teaser
  config.blocks.blocksConfig.teaser = {
    ...config.blocks.blocksConfig.teaser,
    schemaEnhancer: composeSchema(
      defaultStylingSchema,
      IPNTeaserSchemaEnhancer,
    ),
  };
  config.registerComponent({
    name: 'Teaser',
    component: TeaserBlockEventBody,
    dependencies: 'Event',
  });
  config.registerComponent({
    name: 'Teaser',
    component: TeaserBlockProjectBody,
    dependencies: 'project',
  });
  config.registerComponent({
    name: 'Teaser',
    component: TeaserBlockPersonBody,
    dependencies: 'person',
  });
  config.registerComponent({
    name: 'Teaser',
    component: TeaserBlockFileBody,
    dependencies: 'File',
  });

  // Core Grid
  config.blocks.blocksConfig.gridBlock = {
    ...config.blocks.blocksConfig.gridBlock,
    // Enhance grids with linkmore
    schemaEnhancer: composeSchema(defaultStylingSchema, IPNGridSchemaEnhancer),
    // Enhance teasers in grids but without the color picker
    blocksConfig: {
      ...config.blocks.blocksConfig.gridBlock.blocksConfig,
      teaser: {
        ...config.blocks.blocksConfig.gridBlock.blocksConfig.teaser,
        schemaEnhancer: IPNTeaserSchemaEnhancer,
      },
      listing: {
        ...config.blocks.blocksConfig.gridBlock.blocksConfig.listing,
        schemaEnhancer: IPNListingSchemaEnhancer,
        variations: [
          ...config.blocks.blocksConfig.gridBlock.blocksConfig.listing
            .variations,
          ...listingVariations,
        ],
      },
      image: {
        ...config.blocks.blocksConfig.gridBlock.blocksConfig.image,
        view: ImageView,
        edit: Edit,
        schemaEnhancer: imageBlockSchemaEnhancer,
        dataAdapter: ImageBlockDataAdapter,
      },
    },
  };

  // Slider
  config.blocks.blocksConfig.slider.referenceContainerQuery =
    'body.has-sidebar';
  config.blocks.blocksConfig.slider.variations = [
    {
      id: 'default',
      title: 'Default Slider',
      isDefault: true,
      referenceContainerQuery: '.block .full-width',
      view: DefaultSliderBody,
    },
    {
      id: 'ipnslider',
      title: 'IPN Slider',
      isDefault: false,
      view: IPNSliderBody,
    },
  ];

  // Slate
  config.blocks.blocksConfig.slate = {
    ...config.blocks.blocksConfig.slate,
    schemaEnhancer: defaultStylingSchema,
    sidebarTab: 1,
    view: IPNTextBlockView,
  };

  // Search
  config.blocks.blocksConfig.search = {
    ...config.blocks.blocksConfig.search,
    schemaEnhancer: defaultStylingSchema,
  };

  // Accordion
  config.blocks.blocksConfig.accordion.titleIcons.closed.rightPosition =
    plusSVG;
  config.blocks.blocksConfig.accordion.titleIcons.opened.rightPosition =
    minusSVG;

  //  Content-type views
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      person: PersonView,
      publication: PublicationView,
      project: ProjectView,
    },
  };

  // For some reason the description block is disabled (maybe by an addon)
  config.blocks.blocksConfig.description.restricted = false;
  // Remove the default hero block, we have our own "LageHero"
  delete config.blocks.blocksConfig.hero;

  return config;
};

export default applyConfig;
